.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background-color: var(--background-color); /* Uses theme background color */
  border-top: 1px solid var(--border-color); /* Uses theme border color */
  position: fixed; /* Ensure the footer is fixed at the bottom */
  width: 100%;
  bottom: 0;
  left: 0; /* Ensure the footer spans the entire width */
  z-index: 1000; /* Ensure the footer is always on top */
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: var(--dark-text-color); /* Uses theme text color */
}

.footer-icon {
  font-size: 24px;
  color: var(--dark-text-color); /* Uses theme text color */
  margin-bottom: 4px;
}

.footer-item span {
  font-family: 'Arial', sans-serif;
}

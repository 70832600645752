.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Added padding for some spacing */
  background-color: var(--background-color); /* Uses theme background color */
  border-bottom: 1px solid var(--border-color); /* Uses theme border color */
  position: fixed; /* Ensures the header is fixed at the top */
  width: 100%;
  top: 0;
  height: 60px;
  left: 0; /* Ensures the header spans the entire width */
  z-index: 1000; /* Ensures the header is always on top */
}

.header-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px; /* Adjust the height of the logo image */
  margin-right: 10px; /* Spacing between logo image and text */
}

.logo-text {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #fb636c, #be306d); /* Gradient for text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-right {
  display: flex;
  align-items: center;
}

.menu-icon, .profile-icon {
  font-size: 24px;
  margin-left: 20px;
  cursor: pointer;
  color: var(--dark-text-color); /* Uses theme text color */
}

.profile-icon {
  font-size: 32px;
  margin-left: 20px;
}

/* Global Styles */

body[data-theme="dark"] {
  --primary-color: linear-gradient(90deg, #e45b66, #a8336b); /* Softer gradient for buttons */
  --secondary-color: #1a1a4f; /* Lighter dark blue background */
  --accent-color: #2e2e94; /* Softer accent color for secondary text, borders, and dividers */
  --dark-text-color: #b6b3b3; /* Soft white for primary text color */
  --light-text-color: #e0e0e0; /* Keep lighter text for secondary elements */
  --background-color: #1a1a4f; /* Softer dark background */
  --border-color: #2e2e94; /* Softer accent color used for borders and dividers */
  --border-color-2: #a8336b;
  --input-text-color: #2e2e94;
  --card-background-color:#b6b3b3 ;
}

body[data-theme="light"] {
  --primary-color: linear-gradient(90deg, #56a8dc, #48c78e); /* Softer gradient for buttons in light theme */
  --secondary-color: #f0f0f0; /* Off-white background */
  --accent-color: #e0e0e0; /* Softer accent color for secondary text, borders, and dividers */
  --dark-text-color: #505050; /* Slightly softer dark text color */
  --light-text-color: #ffffff; /* Keep lighter text for secondary elements */
  --background-color: #fafafa; /* Softer light background */
  --border-color: #d0d0d0; /* More neutral border color */
  --border-color-2: #48c78e; /* Softer accent color for borders */
  --input-text-color: #48c78e;
  --card-background-color:#ffffff ;

}


/* CSS for Additional Services Tags */
.tag {
  border-color: var(--border-color-2);
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 5px;
}

.tag:hover {
  background-color: var(--border-color-2); /* Uses border color on hover */
  color: var(--light-text-color); /* Keeps the text color consistent */
}

.tag-selected {
  background-color: var(--border-color-2); /* Uses primary gradient background when selected */
  color: var(--light-text-color); /* Light text color for contrast */
  border-color: var(--border-color-2); /* Keep border consistent */
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--dark-text-color);
  margin: 0;
  padding: 0;
}

/* Additional CSS for Buttons, Inputs, Cards, etc. remains unchanged, using the CSS variables defined above */


/* Headings */
h1, h2, h3, h4, h5, h6 {
  color: var(--dark-text-color);
  font-weight: bold;
  margin-bottom: 16px;
}

/* Buttons */
.button-primary {
  background: var(--primary-color);
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background: linear-gradient(90deg, #e5505b, #a3255a); /* Slightly darker gradient on hover */
}

.button-secondary {
  background-color: var(--secondary-color);
  color: var(--dark-text-color);
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-secondary:hover {
  background-color: var(--accent-color);
  color: #FFFFFF; /* Change text color to white on hover */
}

/* Inputs */
.input-field {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  color: var(--dark-text-color);
  background-color: var(--background-color);
}

.input-field:focus {
  outline: none;
  border-color: #fb636c; /* Focus border color to match gradient start */
}

/* Cards */
.card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.card-content {
  padding: 16px;
  text-align: center;
}

/* Text */
.text-primary {
  color: #fb636c; /* Match the start of the gradient for primary text highlights */
  font-weight: bold;
}

.text-secondary {
  color: var(--light-text-color);
}

.text-large {
  font-size: 24px;
  font-weight: bold;
}

.text-medium {
  font-size: 16px;
  font-weight: normal;
}

.text-small {
  font-size: 14px;
  font-weight: normal;
}

/* Sections */
.section {
  padding: 40px 20px;
  text-align: center;
  background-color: var(--secondary-color);
}

.section-title {
  font-size: 32px;
  color: var(--dark-text-color);
  margin-bottom: 24px;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
}

.navbar-item {
  color: var(--dark-text-color);
  font-size: 16px;
  margin: 0 16px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-item:hover {
  color: #fb636c; /* Match hover color with primary color */
}

/* Footer */
.footer {
  padding: 24px;
  background-color: var(--background-color);
  border-top: 1px solid var(--border-color);
  text-align: center;
  font-size: 14px;
  color: var(--light-text-color);
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--dark-text-color);
}

.signup-step {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin: 20px 0;
}

.signup-title {
  margin-bottom: 20px;
}

.signup-row {
  margin-bottom: 16px;
}

.signup-select,
.signup-input {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  /* background-color: var(--light-text-color); */
  color: var(--input-text-color);
}

.signup-input::placeholder,
.signup-select .ant-select-selection-placeholder {
  color: var(--input-text-color);
}

.signup-checkbox {
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: var(--dark-text-color);
}

.signup-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: var(--primary-color);
  color: var(--light-text-color);
  border: none;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background: var(--border-color-2);
}

.checkbox-wrapper {
  text-align: left;
  margin-bottom: 16px;
  color: var(--light-text-color);
}

/* OTP Input Screen Specific Styles */
.otp-info {
  color: var(--dark-text-color);
  margin-bottom: 20px;
}

.otp-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--dark-text-color);
}

.resend-otp-button {
  color: var(--primary-color);
  margin-left: 10px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.otp-input {
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  margin: 0 5px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--dark-text-color);
}

.otp-input:focus {
  border-color: var(--primary-color);
}

.custom-placeholder::placeholder {
  color: var(--dark-text-color);
}
